@import "@fontsource/inter";
@import "@fontsource/inter/300.css"; 
@import "@fontsource/inter/400.css"; 
@import "@fontsource/inter/500.css"; 
@import "@fontsource/inter/600.css"; 

body.theme-rocketcloud {
    font-family: 'Inter', sans-serif!important;
}

body {
    overflow-x: hidden;
    resize: none!important

}
.App {
  text-align: center;
  max-width: unset!important;
  width: 100%!important;
  padding: 0!important;
  margin: 0!important;
}

:root {
  --bs-blue: #39d0ff;
}

.btn-primary {
  --bs-btn-bg: #39d0ff;
  --bs-btn-border-color: #39d0ff;
  --bs-btn-hover-bg: #19b0df;
  --bs-btn-hover-border-color: #19b0df;
  --bs-btn-active-bg: #39d0ff;
  --bs-btn-active-border-color: #39d0ff;
  --bs-btn-disabled-bg: #39d0ff;
  --bs-btn-disabled-border-color: #39d0ff;
}

#region-select .dropdown-menu {
  border: unset;
  box-shadow: 0 0 30px 0 rgba(37,45,51,.15);
  width: 100%;
}

button#region-select {
    width: 100%;
}

.card {
  text-align: left;
  padding: 3em;
  border: unset!important;
  max-width: 800px;
  border-radius: 0.25rem;
  box-shadow: 0 10px 30px 0 rgba(37, 45, 51, .15);
  margin-bottom: 3rem;
  
}

.logo {
  height: 40px;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

body.theme-rocketcloud .logo-title {
    color: #222222;
}
body.theme-rocketcloud h2, body.theme-rocketcloud h1 {
    color: #2664dc!important;
}

body.theme-rocketcloud .App.container {
    background-color: #ecf3fd!important;
    font-size: 1.5em;
    background-position: right bottom!important;
    background-size: 40%!important;
    background-repeat: no-repeat!important;
}

body.theme-rocketcloud .btn-primary {
    --bs-btn-bg: #2664dc;
    --bs-btn-border-color: #2664dc;
    --bs-btn-hover-bg: #19b0df;
    --bs-btn-hover-border-color: #19b0df;
    --bs-btn-active-bg: #2664dc;
    --bs-btn-active-border-color: #2664dc;
    --bs-btn-disabled-bg: #2664dc;
    --bs-btn-disabled-border-color: #2664dc;
    white-space: nowrap!important;
    justify-content: center!important;
    padding: 8px 20px!important;
    background: #2664dc !important;
    background-image: initial !important;
    background-position-x: initial !important;
    background-position-y: initial !important;
    background-size: initial !important;
    background-repeat: initial !important;
    background-attachment: initial !important;
    background-origin: initial !important;
    background-clip: initial !important;
    background-color: rgb(38, 100, 220) !important;
    border: 1px solid #2664DC !important;
    font-style: normal!important;
    font-weight: 400!important;
    font-size: 16px!important;
    line-height: 19px!important;
    color: #fff !important;
}
body.theme-rocketcloud .btn-primary:disabled {
    pointer-events: none;
    opacity: 0.65;
}
body.theme-rocketcloud .btn-primary {
}
body.theme-rocketcloud .btn-primary:hover {
    background: #749ce9 !important;
    border: 1px solid #749CE9 !important;
    color: #fff !important;
}

body.theme-rocketcloud .card {
    box-shadow: unset;
    background: #fff3;
    border: 2px solid #ffffff !important;
    backdrop-filter: blur(15.5px);
    border-radius: 15px !important;
}

body.theme-rocketcloud input,body.theme-rocketcloud textarea {
    outline: unset!important;
    border: unset!important;
    background-color: #fff!important;
    color: #000!important;
    padding: 14px 28px!important;
    font-weight: 300!important;
    border-radius: 2px!important;
    box-shadow: unset!important;
}

body.theme-rocketcloud input::placeholder,body.theme-rocketcloud textarea::placeholder {
    color: #000!important;
    outline: unset!important;
    box-shadow: unset!important;
}

body.theme-rocketcloud input:focus,body.theme-rocketcloud textarea:focus {
    outline: unset!important;
    box-shadow: unset!important;
    background-color: #d2dff8!important;
}
